import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientState, State, isOnly한글 } from '..';
import Spacing from '../../../component/Spacing';
import Text from '../../../component/Text';
import c from '../../../util/c';
import ImagesContainer from './ImagesContainer';

const INPUT_CLASSNAME = c(
  'h-[36px]',
  'text-black, font-Pretendard-SemiBold text-[16px] leading-[30px]',
);

export type ModalProgress = 'close' | 'open' | 'submit';

type ConfirmModalProps = {
  state: State;
  progress: ModalProgress;
};

const ConfirmModal = ({ state, progress }: ConfirmModalProps) => {
  const navigate = useNavigate();

  // 유효성 검사 함수
  const validateForm = (): boolean => {
    if (!state.hospitalName) {
      return false;
    }
    if (!state.patientId) {
      return false;
    }
    if (!state.patientName) {
      return false;
    }
    if (!isOnly한글(state.patientName)) {
      return false;
    }
    if (!state.birthDate) {
      return false;
    }
    if (state.birthDate && state.birthDate.length !== 8) {
      return false;
    }
    if (state.photos.length === 0) {
      return false;
    }
    if (state.patientState === PatientState.NOT_INQUIRY) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!validateForm()) navigate('/', { replace: true });

    const handlePopState = () => {
      console.log('popstate');
      navigate('/', { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  if (progress === 'close') return undefined;

  return (
    <div
      className={c(
        'absolute left-1/2 top-1/2 z-10 h-full w-full rounded bg-white px-[16px]',
        '-translate-x-[50%] -translate-y-[50%]',
        'flex flex-col justify-start',
      )}
    >
      <div className={c('scrollbar-hidden h-full overflow-y-auto')}>
        <Spacing height='h-[44px]' />
        <div className={c('py-[12px]', 'flex flex-col items-center justify-center')}>
          <Text
            size={20}
            lineHeight='leading-[20px]'
            color='text-[#000000]'
            weight='Bold'
          >
            접수 확인
          </Text>
          <Spacing height='h-[10px]' />
          <Text
            size={16}
            lineHeight='leading-[20px]'
            color='text-Gray/50'
            weight='SemiBold'
          >
            작성하신 정보로 접수할까요?
          </Text>
        </div>
        <div className={c('flex flex-col', 'pb-[20px]', 'w-full')}>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            접수정보{' '}
          </Text>
          <div className={c('bg-Gray', 'p-6', 'rounded-lg')}>
            <div className={c('flex justify-between')}>
              <Text
                size={14}
                weight='SemiBold'
                color='text-[#000000]'
                lineHeight='leading-[30px]'
              >
                병원명{' '}
              </Text>
              <p className={c(INPUT_CLASSNAME)}>{state.hospitalName}</p>
            </div>
            <div className={c('flex justify-between')}>
              <Text
                size={14}
                weight='SemiBold'
                color='text-[#000000]'
                lineHeight='leading-[30px]'
              >
                환자 고유번호{' '}
              </Text>
              <p className={INPUT_CLASSNAME}>{state.patientId}</p>
            </div>
            <div className={c('flex justify-between')}>
              <Text
                size={14}
                weight='SemiBold'
                color='text-[#000000]'
                lineHeight='leading-[30px]'
              >
                환자 이름{' '}
              </Text>
              <p className={c(INPUT_CLASSNAME)}>{state.patientName}</p>
            </div>
            <div className={c('flex justify-between')}>
              <Text
                size={14}
                weight='SemiBold'
                color='text-[#000000]'
                lineHeight='leading-[30px]'
              >
                환자 생년월일
              </Text>
              <p className={INPUT_CLASSNAME}>{state.birthDate}</p>
            </div>
            <div className={c('flex justify-between')}>
              <Text
                size={14}
                weight='SemiBold'
                color='text-[#000000]'
                lineHeight='leading-[30px]'
              >
                접수 일자
              </Text>
              <p className={INPUT_CLASSNAME}>{state.submitDate}</p>
            </div>
          </div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환부사진{' '}
          </Text>
          <ImagesContainer photos={state.photos} />
        </div>
      </div>
      <div className='flex gap-4 py-[20px]'>
        <button
          onClick={() => navigate(-1)}
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-[#343434]',
            'rounded-[8px] bg-Gray',
          )}
        >
          취소
        </button>
        <button
          onClick={() => navigate('/?step=submit', { replace: true })}
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-white',
            'rounded-[8px] bg-blue_main_2',
          )}
        >
          접수
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
