import c from '../../../util/c';

const ProgressRing = (props: { isLoading: boolean }) => {
  if (!props.isLoading) return undefined;

  return (
    <div
      className={c(
        'absolute left-1/2 top-1/2 z-20 h-full w-full rounded bg-black/30 px-[16px]',
        '-translate-x-[50%] -translate-y-[50%]',
        'flex items-center justify-center text-center',
      )}
    >
      <div className='h-12 w-12 animate-spin rounded-full border-4 border-gray-300 border-t-white' />
    </div>
  );
};

export default ProgressRing;
